
import { defineComponent } from 'vue';

import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import moment from 'moment';

export default defineComponent({
  name: 'add-claim-2',
  components: {
    Datatable,
  },
  data() {
    return {
      bill: {
        tranche_id: '',
        entity_id: '',
        entity_contract_id: '',
        bill_date: '',
        bill_sequence_no: '',
      },
      batch: {},
      tranches: [],
      entityInfos: [],
      allBillNo: [],
      billList: [],
      batchList: [],
      contractInfos: [],
      claimBillkey: 0,
      tolatTrainee: 0,
      tolatAssessedTrainee: 0,
      tolatCertifiedTrainee: 0,
      tolatBillAmount: 0,
      contributionPay: 0,
      totalContributionAmount: 0,
      totalPayableAmount: 0,
      load: false,
      loading: false,
      courseInfoData: false,
      showBillList: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getTranche();
    await this.getAssociation();
    this.bill.bill_date = moment().format('YYYY-MM-DD');
    this.emitter.on('claim-2-bill-update', async () => {
      await this.getBillList();
    });
  },
  methods: {
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityContract() {
      this.load = true;
      await ApiService.get(
        'entity/contract/list?entity_info_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id
      )
        .then((response) => {
          this.contractInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAllBillNo() {
      this.load = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'bill/claim_1/all_bill_no?entity_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id +
          '&entity_contract_id=' +
           this.bill.entity_contract_id +
          '&institute_info_id=' +
           institute_info_id +
          '&active_status=3'
      )
        .then((response) => {
          this.allBillNo = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getBillList() {
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let data = {
        tranche_id: this.bill.tranche_id,
        entity_id: this.bill.entity_id,
        bill_sequence_no: this.bill.bill_sequence_no,
        bill_date: this.bill.bill_date,
        entity_contract_id: this.bill.entity_contract_id,
        institute_info_id: institute_info_id,
      };
      this.load = true;
      this.loading = true;
      await ApiService.post('bill/claim_2/save', data).then((response) => {
        this.loading = false;
        if (response.status == 200) {
          if (response.data.status == 'error') {
            this.load = false;
            Swal.fire({
              title: 'Error!',
              html: response.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          } else {
            if (response.data.data[0]?.certification_miss_batch_info) {
              this.batchList = response.data.data;
              this.billList = [];
              this.load = false;
            } else {
              this.billList = response.data.data.billList;
              this.tolatTrainee = response.data.data.total_enroll;
              this.tolatAssessedTrainee = response.data.data.total_trainee;
              this.tolatCertifiedTrainee = response.data.data.total_certified;
              this.tolatBillAmount = response.data.data.total_bill_amount;
              this.contributionPay = response.data.data.contribution_percentage;
              this.totalPayableAmount = response.data.data.total_net_amount;
              this.totalContributionAmount =
                response.data.data.total_contribution_amount;
              this.batchList = [];
              this.load = false;
            }
            this.claimBillkey += 1;
            this.showBillList = true;
            this.load = false;
          }
        } else {
          this.load = false;
          let err = '';
          for (const field of Object.keys(response.data.errors)) {
            err += response.data.errors[field][0] + '<br>';
          }
          Swal.fire({
            title: 'Please check all the required field',
            html: err,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        }
      });
    },
    async submitBill() {
      let user_id = VueCookieNext.getCookie('_seip_user');
      user_id =  user_id.id;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }

      let data = {
        tranche_id: this.bill.tranche_id,
        entity_id: this.bill.entity_id,
        bill_sequence_no: this.bill.bill_sequence_no,
        institute_info_id: institute_info_id,
        start_date: this.bill.bill_date,
        user_id: user_id,
      };

      this.loading = true;
      await ApiService.post('bill/claim_2/bill_submit', data)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            this.billList = [];
            this.batchList = [];
            this.tolatTrainee = 0;
            this.tolatAssessedTrainee = 0;
            this.tolatCertifiedTrainee = 0;
            this.tolatBillAmount = 0;
            this.contributionPay = 0;
            this.totalPayableAmount = 0;
            this.totalContributionAmount = 0;
            this.claimBillkey += 1;
            this.showBillList = false;
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {});
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
    confirmSubmitBill() {
      Swal.fire({
        title: "You can't edit bill after submit. Are you sure to submit ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Submitted!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.submitBill();
        }
      });
    },
    moment() {
      return moment();
    },
  },
  setup() {},
});
